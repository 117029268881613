import throttle from 'lodash/throttle';
import { Controller } from 'stimulus';
import Swiper, { EffectFade, Pagination } from 'swiper';

Swiper.use([Pagination, EffectFade]);

export default class extends Controller {
  connect() {
    const eventCards = this.element.querySelectorAll('.event_card');
    const lastEventCard = eventCards[eventCards.length - 1];
    if (lastEventCard) {
      lastEventCard.addEventListener('eventCardProcessed', this.handleLastEventCardProcessed);
    }
  }

  disconnect() {
    const eventCards = this.element.querySelectorAll('.event_card');
    const lastEventCard = eventCards[eventCards.length - 1];
    if (lastEventCard) {
      lastEventCard.removeEventListener('eventCardProcessed', this.handleLastEventCardProcessed);
    }
    if (this.swiper) {
      this.swiper.destroy(true, true);
    }
  }

  handleLastEventCardProcessed = () => {
    // limit the slider to 2 slides
    this.element.querySelectorAll('.event_card').forEach((el, index) => {
      if (index >= 2) el.remove();
    });
    this.initSlider();
  };

  initSlider() {
    this.swiper = new Swiper('.js-eventsSlider', {
      slidesPerView: 1,
      centeredSlides: true,
      speed: 800,
      spaceBetween: 30,
      autoHeight: false,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      breakpoints: {
        700: {
          slidesPerView: 1,
          centeredSlides: false,
        },
        1024: {
          centeredSlides: false,
          slidesPerView: 2,
          spaceBetween: 60,
        },
      },
    });
  }
}
